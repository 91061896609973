


.numbers {
  text-align: center;
  color: rgb(255, 255, 255);
  -webkit-animation: fadeinout 2s linear forwards;
  animation: fadeinout 2s linear forwards;
  }
  
  @-webkit-keyframes fadeinout {
  0% { opacity: 0; }
  100% { opacity: 2; }
  }
  
  @keyframes fadeinout {
  0% { opacity: 0; }
  100% { opacity: 2; }
  }
  







.hourss {
text-align: center;
color: rgb(0, 0, 0);
}








// Property used this way for glass button to avoid having grey text with whie background
.glassButton {
text-align: center;
  color: rgb(255, 255, 255);
}

.home {
  text-align: center;
  color: rgb(255, 255, 255);
  font-size: 40px;
  
}











// Beer Bottle Design

.waters {

position: relative;
display: flex;
justify-content: center;

}




.water {

position: relative;
width: 80px;
height: 80px;
display: flex;
justify-content: center;
background-color: #FBB117;
box-shadow: inset 0 0 50px #8d8f8480;
clip-path: polygon(45% 6%, 54% 6%, 55% 38%, 59% 45%, 60% 84%, 41% 84%, 40% 46%, 44% 39%);
}

.water::before {
content: "";
width: 200%;
height: 200%;

position: absolute;
top: -150%;
left: -50%;
border-radius: 40%;
animation: anim 12s linear infinite;
}

.water::after {
content: "";
width: 200%;
height: 200%;
background-color: #8d8f8480;
position: absolute;
top: -150%;
left: -52%;
border-radius: 40%;
animation: anim 12s linear infinite;
animation-delay: 0.5s;
}

@keyframes anim {
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}
}





















// Navbar
.sticky {
transition: all 0.3s ease-out 0s !important;
}

.navbar {
position: fixed !important;
transition: all 0.3s ease-out 0s !important;
padding: 0.3rem 2rem !important;
font-size: 1.2rem !important;
}

.navbar-toggler {
position: relative !important;
right: -30%;
color: transparent !important;
}


// X in Navbar
.navbar-toggler span {
display: block !important;
background-color: #6b1e0f !important;
height: 4px !important;
width: 27px !important;
margin-top: 5px !important;
margin-bottom: 5px !important;
transform: rotate(0deg) !important;
left: 0 !important;
opacity: 1 !important;
}



.navbar-toggler span:nth-child(1),.navbar-toggler span:nth-child(2),
.navbar-toggler span:nth-child(3) {
transition: transform 0.3s ease-in-out !important;
transition: transform 0.3s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
position: absolute !important;
left: 12px !important;
top: 10px !important;
transform: rotate(135deg) !important;
opacity: 0.9 !important;

}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
height: 12px !important;
visibility: hidden !important;
transform: rotate(90deg) !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
position: absolute !important;
left: 12px !important;
top: 10px !important;
transform: rotate(-135deg) !important;
opacity: 0.9 !important;
}


// mobile navbar 
@media (max-width: 767px) {
.navbar {
  font-size: 1.4rem !important;
}
.navbar-nav .nav-item a::after {
  display: none !important;
}
.navbar-nav .nav-link {
  color: #e2e2e2 !important;
  width: 180% !important;
  margin-right:40px !important;
  padding: 0.5 rem !important;
  }
}

.navbar-brand {
color: rgb(250, 250, 250) !important;
}

.logo {
height: 1.4em !important;
width: 2.5em !important;

}

.nav-link:hover {
letter-spacing: 5px;
}


.nav-link {
padding: 0.8rem 1rem !important;
color: rgb(255, 255, 255);
transition: 0.3s all;
display: block;
justify-content: center !important;
transition: 0.2s all;
color: rgb(255, 254, 254) !important;
}








@media (max-width: 767px) {
.nav-link {
  padding: 0.7rem 1rem !important;
}
}

.navbar-nav .nav-item {
position: relative;
margin-left: 10px;
}

.navbar-nav .nav-item a {
font-weight: 400;
transition: all 0.3s ease-out 0s;
position: relative;
z-index: 1;
}

.navbar-nav .nav-item a::after {
content: "";
position: relative;
display: block;
height: 5px;
width: 0;
border-radius: 16px;
bottom: 1px;
left: 0;
z-index: -1;
transition: all 0.3s ease-out 0s;
}
.navbar-nav .nav-item a:hover::after {
width: 97%;
}








.bar {
right: 150px;
color: rgba(0, 244, 195, 0.953);
}



.centerNav {
text-align: center;
}

.picture {
position: absolute;
right: 0%;
top: 0%;
}

// Referring to comment on navbarJSX

.LOGO {
  position: absolute;
  right: 0%;
  top: -70%;
}

@media only screen and (min-width: 100px) {

.LOGO2 {
position: absolute;
right: 0%;
top: 0%;
}
}


//Swiper
.swiper {
width: 100%;
height: 100%;
}

.swiper-slide {
text-align: center;

/* Center slide text vertically */
display: -webkit-box;
display: -ms-flexbox;
display: -webkit-flex;
display: flex;
-webkit-box-pack: center;
-ms-flex-pack: center;
-webkit-justify-content: center;
justify-content: center;
-webkit-box-align: center;
-ms-flex-align: center;
-webkit-align-items: center;
align-items: center;

}

.swiper-slide img {
display: block;
width: 100%;
height: 100%;
object-fit: cover;
}



.swiper-button-next,
          .swiper-button-prev {
            padding: 8px 16px !important;
            border-radius: 100% !important;
            color: #6b1e0f !important;
          }
          .swiper-pagination-bullet{
            background-color: #6b1e0f !important;
          }


.storeNum {
color: rgb(0, 0, 0);
}



.gallerypage
{

text-align: center;
color: rgb(255, 255, 255);
-webkit-animation: fadeinout 1s linear forwards;
animation: fadeinout 1s linear forwards;

width: 100%;
height: auto !important;

}



@-webkit-keyframes fadeinout {
0% { opacity: 0; }
100% { opacity: 2; }
}

@keyframes fadeinout {
0% { opacity: 0; }
100% { opacity: 2; }
}




// video background
.vid{
width: 100%;
height: 150%;
object-fit: cover;
}

.content {
position: absolute;
width: 100%;
height: 100%;
top: 0; 
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
color: rgb(255, 255, 255);
}

.overlay {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.3);
}

.main {
width: 100%;
height: 100vh;
}
.footer {
color:rgb(255, 255, 255);
font-size: 14px;
}

